

declare const __appVersion: string;


export function logError(error: any, component: string) {
  const message = {
    appVersion: __appVersion,
    component: component,
    error: error.toString(),
    errorMsg: error.message,
  }
  fetch(`https://api.eventer.app/v1/debug/log-client-error`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      method: "POST",
      body: JSON.stringify(message)
    }).catch(err => console.log(`error on sending error: ${err}`))
}
import * as React from 'react';

import { Position } from '../../common/types';
import { getDistance } from '../../eventer-util';


interface DistanceLabelProps {
  pos1: Position;
  pos2: Position;
}

export class DistanceLabel extends React.Component<DistanceLabelProps, {}> {

  render() {
    const _distance = getDistance(this.props.pos1, this.props.pos2);
    if (_distance < 950) {
      return (<span>{_distance} m</span>)
    }
    if (_distance < 10000) {
      return (<span>{(Math.round(_distance / 10) / 100).toLocaleString('de')} km</span>)
    }
    return (<span>{Math.round(_distance / 1000)} km</span>)
  }
}
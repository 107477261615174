const pad = (v) => {
  return `${v}`.padStart(2, '0');
}

/**
 * @param   date  the date to format
 * @return  examples: '07:30 Uhr'
 */
export function formatTime(date: Date): string {
  const hours = pad(date.getHours());
  const minutes = pad(date.getMinutes());
  return `${hours}:${minutes} Uhr`;
}

/**
 * @param   date  the date to format
 * @return  examples: '22.08.' (if given date is current year), '03.07.2019' (if not current year)
 */
export function formatDate(date: Date): string {
  const day = pad(date.getDate());
  const month = pad(date.getMonth() + 1);
  const year = date.getFullYear();
  if (date.getFullYear() === new Date().getFullYear()) {
    return `${day}.${month}.`;
  } else {
    return `${day}.${month}.${year}`;
  }
}

/**
 * @param   date    the date to format
 * @return  date time string
 */
export function formatDateTime(date: Date): string {
  const dStr = formatDate(date);
  const today = formatDate(new Date());

  if (today === dStr) {
    return formatTime(date);
  }

  return `${dStr} ${formatTime(date)}`;
}
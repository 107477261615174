import * as React from 'react';

import Dialog from '@material-ui/core/Dialog';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, Theme } from '../../../../node_modules/@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import { searchLocations, storeSearchLocation, SearchLocation } from '../../common/search-locations';


const styles = (theme: Theme) => createStyles({
  _centeredListItem: {
    textAlign: 'center',
  },
  _dialogList: {
      padding: '0px',
  },
});

interface SearchLocationChooserProps extends WithStyles<typeof styles> {
  open: boolean;
  onClose: () => void;
  onSearchLocationChanged: (searchLocation: SearchLocation) => void;
}

class SearchLocationChooser extends React.Component<SearchLocationChooserProps, any> {

  public static defaultProps: Partial<SearchLocationChooserProps> = {
    open: true
  }

  onClick(searchLocation: SearchLocation) {
    storeSearchLocation(searchLocation);
    this.props.onSearchLocationChanged(searchLocation);
  }
  
  render() {
    const { classes } = this.props;
    const _lastSearchLocationId = searchLocations[searchLocations.length - 1].id;
    const _listItems = searchLocations.map(searchLocation => {
      return (
        <ListItem
            key={searchLocation.id}
            divider={searchLocation.id != _lastSearchLocationId}>
          <ListItemText
            className={classes._centeredListItem}
            onClick={() => this.onClick(searchLocation)}
            primary={searchLocation.name}/>
        </ListItem>
      )
    });
    return (
      <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          //aria-labelledby='search-location-chooser-title'
          fullWidth={true}
          maxWidth={'md'}>
        <List className={classes._dialogList}>{_listItems}</List>
      </Dialog>)
  }
}

export default withStyles(styles)(SearchLocationChooser);
import * as React from 'react';

import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '../../../../node_modules/@material-ui/core';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';


const styles = (theme: Theme) => createStyles({
  _padded: {
    padding: '30px',
  },
})

interface InfoPanelProps extends WithStyles<typeof styles> {
  mainText, infoText?: string;
}

class InfoPanel extends React.Component<InfoPanelProps, {}> {
  // TODO: make nice ui
  render() {
    const { classes } = this.props;
    return (
      <Typography className={classes._padded} align='center' variant='subheading'>
        { this.props.mainText }
        { this.props.infoText ? (
          <div style={{ paddingTop: '20px' }}>{this.props.infoText}</div>
        ) : (
          null
        )}
      </Typography>
    )
  }
}

export default withStyles(styles)(InfoPanel);
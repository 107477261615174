import { Event, Place } from "../../eventer/eventer-types";
import { id } from "date-fns/esm/locale";


export interface EventFilterRule {
  event?: Event;
  place?: Place;
  category?: string;
}

export interface RuleEntry {
  id: string;
  enabled: boolean;
  text: string;
  rule: EventFilterRule;
}

export class EventFilter {

  private places: Map<string, Place> = new Map<string, Place>();
  private categories: Map<string, string> = new Map<string, string>();


  /**
   * @returns True if initialization was successful, false if there where errors
   *    (with the result that not all given filter data could be converted, only partly).
   */
  initialize(data: any):boolean {
    
    this.places.clear();
    this.categories.clear();

    let errorOccured = false;
    if (data) {

      if (data.places) {
        Object.keys(data.places)
        .forEach(id => {
          try {
            const placeData = data.places[id];
            this.places.set(id, new Place(placeData.placeEntity));
          } catch (err) {
            console.log(`error on initialize places from data: ${data}`);
            errorOccured = true;
          }
        });
      }

      if (data.categories) {
        Object.keys(data.categories)
          .forEach(id => {
            try {
              this.categories.set(id, data.categories[id]);
            } catch (err) {
              console.log(`error on initialize categories from data: ${data}`);
              errorOccured = true;
            }
          });
      }
    }

    return !errorOccured;
  }

  buildFilterData(): any {
    
    const filterData = {
      places: {},
      categories: {},
    };

    this.places.forEach((place, id) => {
      const placeData = {
        placeEntity: place.placeEntity,
      }
      filterData.places[id] = placeData;
    });

    this.categories.forEach((category, id) => filterData.categories[id] = category);

    return filterData;
  }


  isFiltered(event: Event): boolean {
    
    if (this.places && this.places.get(event.google_place_id) != undefined) {
      return true;
    }

    if (this.categories && event.categories && event.categories.length > 0) {
      let allFound: boolean = true;
      event.categories.forEach(category => {
        if (this.categories.get(category) == undefined) {
          allFound = false;
        }
      });
      if (allFound) {
        return true;
      }
    }

    return false;
  }

  buildRuleEntries(event: Event) {
    let rulesEntries: RuleEntry[] = [];

    // place
    rulesEntries.push({
      id: `p:${event.google_place_id}`,
      enabled: this.places.get(event.google_place_id) != undefined,
      text: `Veranstaltungsort: ${event.place.placeEntity.name}`,
      rule: {
        place: event.place,
      },
    });

    // category
    if (event.categories && event.categories.length > 0) {
      
      event.categories.forEach(category => {
        
        // TODO: define categories
        let text = `Alle Veranstaltungen aus der Kategorie: "${category}"`;
        if (category == 'exhibition') {
          text = `Alle Ausstellungen`;
        }
        if (category == 'meetup') {
          text = `Alle Meetups`;
        }
        if (category == 'movie') {
          text = `Alle Kinofilme`;
        }
        if (category == 'sport') {
          text = `Alle Sportveranstaltungen`;
        }

        rulesEntries.push({
          id: `c:${category}`,
          enabled: this.categories.get(category) != undefined,
          text: text,
          rule: {
            category: category,
          },
        });
      });
    }

    return rulesEntries;
  }

  addRule(rule: EventFilterRule): boolean {
    
    if (rule.category) {
      if (this.categories.get(rule.category) != undefined) {
        // already set
        return false;
      }
      this.categories.set(rule.category, rule.category);
      return true;
    }

    if (rule.place) {
      if (this.places.get(rule.place.placeEntity.google_place_id) != undefined) {
        // already set
        return false;
      }
      this.places.set(rule.place.placeEntity.google_place_id, rule.place);
      return true;
    }

    return true;
  }

  removeRule(rule: EventFilterRule): boolean {
    
    if (rule.category) {
      return this.categories.delete(rule.category);
    }
    
    if (rule.place) {
      return this.places.delete(rule.place.placeEntity.google_place_id);
    }

    return false;
  }
}

import {  GeoAreaBoundingBox, Position } from './common/types';


/**
 * Returns the distance of 2 positions in meters.
 */
export function getDistance(p1: Position, p2: Position): number {
  let radlat1 = Math.PI * p1.lat/180;
  let radlat2 = Math.PI * p2.lat/180;
  let theta = p1.lon-p2.lon;
  let radtheta = Math.PI * theta/180;
  let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
  dist = Math.acos(dist);
  dist = dist * 180/Math.PI;
  dist = dist * 60 * 1.1515;
  dist = dist * 1.609344;
  return Math.round(dist * 1000);
}

/**
 * Transforms a geopoint with radius in a geo area.
 * 
 * @param geoLocation 
 */
export function geolocationToGeoArea(geolocation): GeoAreaBoundingBox {
  const oneLat = 111300; // in meters
  const latDelta = geolocation.radius / oneLat;
  const oneLon = oneLat * Math.cos(geolocation.position.lat);
  const lonDelta = geolocation.radius / oneLon;
  return {
    top: geolocation.position.lat + latDelta,
    left: geolocation.position.lon - lonDelta,
    bottom: geolocation.position.lat - latDelta,
    right: geolocation.position.lon + lonDelta
  }
}

/**
 * @param date A js date instance or an iso date string or a timestamp (epoch millis).
 * @return The day index as day offset since 1970.
 */
export function getDayIdxByDate(date: number | Date | string): number {
  return Math.round(parseTimestamp(date) / 8.64e7);
}

export function getDateByDayIdx(dayIdx: number): Date {
  return new Date(Date.UTC(1970, 0, dayIdx));
}

export function parseTimestamp(date: number | Date | string): number {
  if (typeof date === 'number') {
    return date;
  } else if (typeof date === 'string') {
    return Date.parse(date);
  } else {
    return (date as Date).getTime();
  }
}
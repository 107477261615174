import { Position } from "./types";

export interface SearchLocation {
  id: string;
  name: string;
  position?: Position;
}

export const searchLocations:SearchLocation[] = [{
    id: '__nearby',
    name: 'in der Nähe',
  }, {
    id: 'c',
    name: 'Chemnitz',
    position: {
      lat: 50.8349026,
      lon: 12.9070748,
    },
  }, {
    id: 'z',
    name: 'Zwickau',
    position: {
      lat: 50.7273939,
      lon: 12.4171143,
    },
  }, {
    id: 'l',
    name: 'Leipzig',
    position: {
      lat: 51.3419134,
      lon: 12.2535525,
    },
  }, {
    id: 'dd',
    name: 'Dresden',
    position: {
      lat: 51.0769658,
      lon: 13.6325033,
    },
  },

  /*{
    id: 'b',
    name: 'Berlin',
    position: {
      lat: 52.5065133,
      lon: 13.1445545,
    },
  },*/
];

export function getSearchLocationById(id: String): SearchLocation | undefined {
  return searchLocations.find(s => s.id == id);
};

export function getStoredSearchLocation(): SearchLocation {
  let _searchLocationId = localStorage.getItem('__eventer.searchLocationId');
  let _searchLocation;
  if (_searchLocationId) {
    _searchLocation = getSearchLocationById(_searchLocationId);
  }
  if (!_searchLocation) {
    _searchLocation = getSearchLocationById('c');
  }
  return _searchLocation;
};

export function storeSearchLocation(searchLocation: SearchLocation): void {
  localStorage.setItem('__eventer.searchLocationId', searchLocation.id);
};
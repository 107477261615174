import { Position, Geolocation } from "../../common/types";
import { geolocationToGeoArea } from '../../eventer-util';
import { Event } from "../../eventer/eventer-types";
import { EventFilter, EventFilterRule } from "./event-filter";
import { ViewEvent } from "./view-types";
import { Event as DbEvent } from "../../eventer/eventer-db";


interface LoadProcess {
  syncId: number;
  geolocation: Geolocation;
}

export interface LoadObserver {
  needMoreEvents(): boolean;
  onUpdateEvents(allViewEvents: Map<string, ViewEvent>, visibleViewEventsById: Map<string, ViewEvent>);
}


export class EventProvider {

  eventer: any;
  observer: LoadObserver;

  position: Position;
  radius: number;

  allViewEventsById: Map<string, ViewEvent> = new Map<string, ViewEvent>();
  visibleViewEventsById: Map<string, ViewEvent> = new Map<string, ViewEvent>();

  currentSyncId?: number;

  startsAtMin: number;
  startsAtMax: number;

  // Flag determining if there are more events which can be loaded from database
  // Note: maybe we have to unset this flag after snycing events in background ...
  moreToLoad: boolean;
  loadStartsAtMin: number;
  loadStartsAtMax: number;

  moreToSync: boolean;
  syncStartsAtMin: number;
  syncStartsAtMax: number;

  filterEnabled: boolean = false;
  filter: EventFilter = new EventFilter();


  constructor(eventer: any, observer: LoadObserver) {
    this.eventer = eventer;
    this.observer = observer;
  }


  initializeFilter(data: any): boolean {
    return this.filter.initialize(data);
  }

  buildFilterData(): any {
    return this.filter.buildFilterData();
  }



  reset(position: Position, radius: number, startsAtMin: number) {

    this.allViewEventsById.clear();
    this.visibleViewEventsById.clear();

    this.currentSyncId = undefined;

    // TODO: maybe position will change (e.g. on '__nearby' mode)
    this.position = position;
    this.radius = radius;

    this.startsAtMin = startsAtMin;
    this.startsAtMax = this.startsAtMin;

    this.moreToLoad = true;
    this.loadStartsAtMin = this.startsAtMin;
    this.loadStartsAtMax = this.loadStartsAtMin;

    this.moreToSync = true;
    this.syncStartsAtMin = this.startsAtMin;
    this.syncStartsAtMax = this.syncStartsAtMin;
  }


  /** loading section */

  async load() {

    // create a new load process instance,
    // all pending loading processes will break and ignored afterwards
    const loadProcess = {
      syncId: Date.now(),
      geolocation: {
        position: this.position,
        radius: this.radius
      }
    }
    this.currentSyncId = loadProcess.syncId;

    try {

      if ((this.moreToLoad) && (this.loadStartsAtMax <= this.startsAtMax)) {
        await this.loadNext(loadProcess);
      }

      while ((this.moreToSync) // primary flag
         && (this.currentSyncId === loadProcess.syncId && this.syncStartsAtMax <= this.startsAtMax
              // special case: nothing more to load and 'syncStartsAtMax' is virtual (not an event startsAt-timestamp)
              || (this.observer.needMoreEvents() && this.loadStartsAtMax >= this.syncStartsAtMax))) {

        const _moreToSync = await this.syncNext(loadProcess);
        if (this.currentSyncId != loadProcess.syncId) {
          return;
        }

        this.moreToSync = _moreToSync;
        if (this.moreToSync && !this.moreToLoad) {
          // if we have synced some events => we have more to load
          this.moreToLoad = true;
        }
      }

      // update loop (ensure that all events from database are loaded until startsAtMax)
      if ((this.moreToLoad) && (this.currentSyncId === loadProcess.syncId && this.loadStartsAtMax <= this.startsAtMax)) {
        await this.loadNext(loadProcess);
      }

    } catch (err) {
      if (err && err.message == 'Failed to fetch') {
        // TODO: nothing to do yet, maybe we should provide a context for offline mode
      } else {
        throw err;
      }
    }
  }

  private async loadNext(loadProcess: LoadProcess) {

    let loadMore = true;
    while (loadProcess.syncId === this.currentSyncId && loadMore) {

      const params = {
        starts_at: this.loadStartsAtMax,
        geolocation: loadProcess.geolocation,
        limit: 30
      }

      const _events = await this.eventer.loadEvents(params)

      if (loadProcess.syncId != this.currentSyncId) {
        return;
      }

      if (_events && _events.length > 0) {
        this.loadStartsAtMax = _events.reduce((_max, _event) => Math.max(_max, _event._startsAtTimestamp), this.loadStartsAtMax);
        this.mergeEvents(_events);
      }

      if (loadProcess.syncId != this.currentSyncId) {
        return;
      }

      this.moreToLoad = _events && _events.length == params.limit;

      // until: if there may be more events to load we have to check if
      //   - synced events time range is still greater to be update-to-date (load until maxSynced = startsAtMax)
      //   - or needMoreEvents is still true
      loadMore = this.moreToLoad && (this.loadStartsAtMax < this.startsAtMax || this.observer.needMoreEvents());
    }
  }

  private async syncNext(loadProcess: LoadProcess) {

    // check if specified time to sync "too far" in future
    if (this.syncStartsAtMax - this.startsAtMin > 1000 * 60 * 60 * 24 * 15) {

      // we do not sync, we perform one search request, maybe events are rare or strong filtered
      const params = {
        geo_area: geolocationToGeoArea(loadProcess.geolocation),
        starts_at_min: this.syncStartsAtMax,
        limit: 30,
      }

      const events = await this.eventer.searchEvents(params);
      if (events && loadProcess.syncId == this.currentSyncId) {
        this._handleSyncEvents(events);
      }

      return events && events.length == params.limit;
    }

    // ok, we start a sync
    const params = {
      geoArea: geolocationToGeoArea(loadProcess.geolocation),
      starts_at_min: this.syncStartsAtMax,
      // sync next ten days ...
      starts_at_max: this.syncStartsAtMax + (1000 * 60 * 60 * 24 * 10),

      syncTimestamp: loadProcess.syncId
    };

    // TODO: check 'eventFlux' may return more events due syncing whole days
    // => 'eventFlux' should honor params (used for filter)
    await this.eventer.syncEvents(params, events => {
        if (events && loadProcess.syncId == this.currentSyncId) {
          this._handleSyncEvents(events);
        }
      });
    if (loadProcess.syncId === this.currentSyncId) {
      this.syncStartsAtMax = Math.max(this.syncStartsAtMax, params.starts_at_max);
    }
    return true;
  }

  private _handleSyncEvents(_events: any[]) {
    const filteredEvents = _events.filter(_event => {
      this.syncStartsAtMax = Math.max(this.syncStartsAtMax, _event._startsAtTimestamp);
      if ((_event._startsAtTimestamp >= this.startsAtMin && _event._startsAtTimestamp <= this.startsAtMax)
          || this.observer.needMoreEvents()) {
        return true;
      }
    });
    this.mergeEvents(filteredEvents);
  }

  private mergeEvents(_newEvents: any[]) {
    if (!_newEvents) {
      return;
    }
    let needsUpdate = false;
    _newEvents.forEach(_newEvent => {

      const dbEvent: DbEvent = _newEvent as DbEvent;

      if (dbEvent.deleted_at != null) {
        // this event has been 'deleted' => remove
        this.visibleViewEventsById.delete(dbEvent.id);
        if (this.allViewEventsById.delete(dbEvent.id)) {
          needsUpdate = true;
        }
        return;
      }

      // TODO: eventer syncer should provide Event type
      const newEvent: Event = new Event(dbEvent, _newEvent._place);

      if (this.allViewEventsById[newEvent.id] && newEvent._updatedAtTimestamp <= this.allViewEventsById[newEvent.id]._updatedAtTimestamp) {
        return;
      }

      if (this.startsAtMin > newEvent._startsAtTimestamp) {
        this.startsAtMin = newEvent._startsAtTimestamp;
      }
      if (this.startsAtMax < newEvent._startsAtTimestamp) {
        this.startsAtMax = newEvent._startsAtTimestamp;
      }

      const viewEvent = new ViewEvent(newEvent, this.filter.isFiltered(newEvent));
      this.allViewEventsById.set(newEvent.id, viewEvent);

      // check filter
      if (this.filterEnabled == false || viewEvent.isFiltered == false) {
        needsUpdate = true;
        this.visibleViewEventsById.set(newEvent.id, viewEvent);
      }
    });
    if (needsUpdate) {
      this.observer.onUpdateEvents(this.allViewEventsById, this.visibleViewEventsById);
    }
  }


  /** filter section */

  /**
   * @returns True, if filter flag has changed, otherwise it return false (no change).
   */

  setFilterEnabled(filterEnabled: boolean): boolean {
    if (this.filterEnabled == filterEnabled) {
      return false;
    }
    this.filterEnabled = filterEnabled;

    this.onFilterChange();
    return true;
  }

  buildRuleEntries(event: Event) {
    return this.filter.buildRuleEntries(event);
  }

  /**
   * @returns True, if given rule was really added, othwerwise false
   *    if will be returned if given rule was already set.
   */
  addEventFilterRule(rule: EventFilterRule): boolean {
    if (this.filter.addRule(rule) === false) {
      // rule already exists => no visible changes
      return false;
    }

    // we have to update
    this.onFilterChange();
    return true;
  }

  /**
   * @returns True, if given rule has been removed, otherwise
   *    false will be returned if given rule wasn't set.
   */
  removeEventFilterRule(rule: EventFilterRule): boolean {
    if (this.filter.removeRule(rule) === false) {
      // rule didn't exist => no visible changes
      return false;
    }

    // we have to update
    this.onFilterChange();
    return true;
  }

  private onFilterChange(): void {

    // check if we didn't have any events yet (e.g. we are in initialization stage)
    if (this.allViewEventsById.size == 0) {
      // we can break here
      return;
    }

    this.visibleViewEventsById.clear();
    this.allViewEventsById.forEach((viewEvent, id, map) => {
      viewEvent.isFiltered = this.filter.isFiltered(viewEvent.event);
      if (this.filterEnabled == false || viewEvent.isFiltered == false) {
        this.visibleViewEventsById.set(viewEvent.event.id, viewEvent);
      }
    });

    this.observer.onUpdateEvents(this.allViewEventsById, this.visibleViewEventsById);
  }
}

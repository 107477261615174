import * as React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import BlockIcon from '@material-ui/icons/Block';

import { formatDateTime } from '../date-util';

import PlaceLabel from '../places/place-label';
import { Theme, createStyles, WithStyles, Grid, IconButton, Dialog } from '@material-ui/core';
import { ViewEvent } from './view-types';
import { EventController } from './event-controller';
import EventFilterRuleChooser from './event-filter-rule-chooser';
import EventDetailView from './event-detail-view';


interface EventListItemProps extends WithStyles<typeof styles> {
  viewEvent: ViewEvent;
  eventController: EventController;
}

interface EventListItemState {
  detailViewIsOpen: boolean;
  filterRuleChooserIsOpen: boolean;
  swipeXOffset: number;
}

const styles = (theme: Theme) => createStyles({
  _cardContent: {
    // reduce top/bottom-padding
    //   + patch: .MuiCardContent-root-xx:last-child { padding-bottom: 24px; }
    paddingBottom: '12px !important',
    paddingTop: '12px',
  },
  _iconButton: {
    height: '40px',
  },
  _icon: {
    height: '20px',
    width: '20px',
  },
});

class EventListItem extends React.Component<EventListItemProps, EventListItemState> {

  _bind() {
    this.onClickOpenDetailView = this.onClickOpenDetailView.bind(this);
    this.onCloseDetailView = this.onCloseDetailView.bind(this);
    this.onCloseFilterRuleChooser = this.onCloseFilterRuleChooser.bind(this);
    this.openFilterRuleChooser = this.openFilterRuleChooser.bind(this);
    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
  }

  private swipeInitialClientX: number = 0;
  private swipeInitialClientY: number = 0;
  private swipeInitialWidth: number = 0;
  private swipeStarted: boolean = false;
  private isSwiping: boolean = false;


  constructor(props) {
    super(props);
    this._bind();
    this.state = {
      detailViewIsOpen: false,
      filterRuleChooserIsOpen: false,
      swipeXOffset: 0,
    };
  }


  onClickOpenDetailView() {
    this.setState((state, props) => {
      return { detailViewIsOpen: true };
    });
  }

  onCloseDetailView() {
    this.setState((state, props) => {
      return { detailViewIsOpen: false };
    });
  }

  openFilterRuleChooser() {
    this.setState((state, props) => {
      return {
        filterRuleChooserIsOpen: true,
        detailViewIsOpen: false,
      }
    });
  }

  onCloseFilterRuleChooser() {
    this.setState((state, props) => {
      return { filterRuleChooserIsOpen: false };
    });
  }

  onTouchStart(e) {

    // HTOFIX: determining the right cardEl (not the clicked on inside)
    var el = e.target;

    try {
      while (el && (el.className && el.className.indexOf('event-list-item') == -1)) {
        el = el.parentNode;
      }
      if (!el || el.className.indexOf('event-list-item') == -1) {
        return;
      }
    } catch (err) {
      // TODO: hapens on click on detail view an closing it
      // => path el with strange className property
    }

    console.log("==> ", el);

    this.swipeStarted = true;
    this.isSwiping = false;
    this.swipeInitialClientX = e.touches[0].clientX;
    this.swipeInitialClientY = e.touches[0].clientY;
    this.swipeInitialWidth = el.offsetWidth;
  }

  onTouchMove(e) {

    if (e.touches && e.touches[0]) {

      // TODO: maybe we need a relative size?
      let swipeYOffset = e.touches[0].clientY - this.swipeInitialClientY;
      if (Math.abs(swipeYOffset) > 50) {
        this.onSwipeEnd();
        return;
      }

      let swipeXOffset = e.touches[0].clientX - this.swipeInitialClientX;

      // use a moving threshold before activating swiping
      // TODO: relative threshold?
      if (!this.isSwiping && Math.abs(swipeXOffset) > 20) {
        this.isSwiping = true;
      }
      
      if (this.isSwiping) {
        this.setState((state, props) => {
          return { swipeXOffset: swipeXOffset };
        });
      }
    }
  }

  private onSwipeEnd() {
    
    // TODO: maybe complete swipe out/back with transition
    this.swipeStarted = false;
    this.isSwiping = false;
    this.swipeInitialClientX = 0;
    this.swipeInitialClientY = 0;
    this.swipeInitialWidth = 0;

    this.setState((state, props) => {
      return { swipeXOffset: 0 }
    });
  }

  onTouchEnd(e) {

    if (this.isSwiping == false) {
      // already breaked swipe process
      return;
    }

    const delta = Math.abs(this.state.swipeXOffset);
    if (delta > (this.swipeInitialWidth / 4)) {
      this.openFilterRuleChooser();
    }

    this.onSwipeEnd();
  }


  render() {

    const { classes } = this.props;

    const viewEvent = this.props.viewEvent;
    const _mainContent = (
      <>
        <Typography component='p' onClick={this.onClickOpenDetailView}>
          {formatDateTime(new Date(viewEvent.event._startsAtTimestamp))} - {viewEvent.event.title}
        </Typography>
        <PlaceLabel place={viewEvent.event.place} onClick={this.onClickOpenDetailView} color='textSecondary'/>
      </>);

    let _cardContent: JSX.Element;
    if (viewEvent.isFiltered) {
      _cardContent = (
        <Grid container>
          <Grid item xs={11}>
            {_mainContent}
          </Grid>
          <Grid item xs={1}>
            <IconButton
                className={classes._iconButton}
                aria-label='Filter bearbeiten'
                aria-haspopup='true'
                onClick={this.openFilterRuleChooser}>
              <BlockIcon className={classes._icon}></BlockIcon>
            </IconButton>
          </Grid>
        </Grid>);
    } else {
      _cardContent = _mainContent;
    }

    let _styles:any = {};
    if (this.state.swipeXOffset != 0) {
      _styles.width = this.swipeInitialWidth;
      _styles.marginLeft = this.state.swipeXOffset + 'px';
    }

    return (
      // TODO: use List (without 3D-effect of cards)
      <Card className='event-list-item' data-event-id={viewEvent.event.id} square={true} elevation={1}
          onTouchStart={this.onTouchStart}
          onTouchMove={this.onTouchMove}
          onTouchEnd={this.onTouchEnd}
          style={_styles}>
        <CardContent className={classes._cardContent}>
          {_cardContent}
        </CardContent>
        <Dialog fullScreen open={this.state.detailViewIsOpen}>
          <EventDetailView
            viewEvent={this.props.viewEvent}
            eventController={this.props.eventController}
            onClose={this.onCloseDetailView}/>
        </Dialog>
        <EventFilterRuleChooser
          viewEvent={this.props.viewEvent}
          eventController={this.props.eventController}
          open={this.state.filterRuleChooserIsOpen}
          onClose={this.onCloseFilterRuleChooser}/>
      </Card>)
  }
}

export default withStyles(styles)(EventListItem);


import * as React from 'react';
import { Position } from './common/types';


export interface PositionContextType {
  enabled?: boolean;
  coords?: Position;
  timestamp?: number;
}

export const PositionContext = React.createContext<PositionContextType>({});
import * as React from 'react';
import update from 'immutability-helper';

import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';

import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CloseIcon from '@material-ui/icons/Close';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import { formatDateTime } from '../date-util';

import PlaceLabel from '../places/place-label';
import { ViewEvent } from './view-types';
import { Theme, createStyles, IconButton, MenuItem, Menu } from '@material-ui/core';
import { EventController } from './event-controller';
import EventFilterRuleChooser from './event-filter-rule-chooser';


interface EventDetailViewProps extends WithStyles<typeof styles> {
  viewEvent: ViewEvent;
  eventController: EventController;
  onClose: () => void;
}

interface EventDetailViewState {
  detailMenuIsOpen: boolean;
  detailMenuAnchorEl?: HTMLElement;
  filterRuleChooserIsOpen: boolean;
}

const styles = (theme: Theme) => createStyles({
  _appBar: {
  },
  _toolbar: {
    alignItems: 'flex-start',
    marginBottom: '4px',
    marginTop: '4px',
    minHeight: '48px', // otherwise there will be a top/bottom-"padding" due alignment
    padding: '0px',
  },
  _toolbarTitleContainer: {
    alignItems: 'flex-end',
    minHeight: '120px',
  },
  _toolbarTitleContent: {
    marginBottom: '4px',
  },
  _leftToolbarButtons: {
    marginLeft: 'auto',
  },
  _detailViewContent: {
    paddingLeft: '12px',
    paddingRight: '12px',
  },
  _detailViewGridRow: {
    paddingTop: '24px',
  },
  _detailViewGridRowIcon: {
    paddingLeft: '16px'
  },
  _detailViewGridRowContent: {
    marginTop: '2px'
  },
});


class EventDetailView extends React.Component<EventDetailViewProps, EventDetailViewState> {

  private _bind() {
    this.onClickOpenDetailMenu = this.onClickOpenDetailMenu.bind(this);
    this.onCloseDetailMenu = this.onCloseDetailMenu.bind(this);
    this.onCloseFilterRuleChooser = this.onCloseFilterRuleChooser.bind(this);
    this.onOpenFilterRuleChooserClick = this.onOpenFilterRuleChooserClick.bind(this);
  }


  constructor(props) {
    super(props);
    this._bind();
    this.state = {
      detailMenuIsOpen: false,
      filterRuleChooserIsOpen: false,
    };
  }


  onClickOpenDetailMenu(e) {
    this.setState(update(this.state, {
      detailMenuIsOpen: { $set: true },
      detailMenuAnchorEl: { $set: e.currentTarget },
    }));
  }

  onCloseDetailMenu(e) {
    this.setState(update(this.state, {
      detailMenuIsOpen: { $set: false },
    }));
  }

  onOpenFilterRuleChooserClick() {
    this.setState(update(this.state, {
      filterRuleChooserIsOpen: { $set : true},
      detailMenuIsOpen: { $set: false },
    }));
  }

  onCloseFilterRuleChooser() {
    this.setState(update(this.state, {
      filterRuleChooserIsOpen: { $set: false },
    }));
  }

  
  render() {
    
    const { classes } = this.props;

    let detailMenuItems: JSX.Element[] = [];
    detailMenuItems.push((
      <MenuItem key={'clearEventFilterRule'} onClick={this.onOpenFilterRuleChooserClick}>
        Filter bearbeiten
      </MenuItem>))

    return (
        <React.Fragment>
          <AppBar
              position='sticky'
              className={classes._appBar}
              color='secondary'>
            <Toolbar className={classes._toolbar}>
              <IconButton
                aria-label='More'
                aria-haspopup='true'
                onClick={this.props.onClose}>
                  <CloseIcon aria-label='Schliessen'/>
              </IconButton>

              <Grid container className={classes._toolbarTitleContainer}>
                <Grid item className={classes._toolbarTitleContent}>
                  <Typography variant="headline">
                    {this.props.viewEvent.event.title}
                  </Typography>
                </Grid>
              </Grid>

              <EventFilterRuleChooser
                viewEvent={this.props.viewEvent}
                eventController={this.props.eventController}
                open={this.state.filterRuleChooserIsOpen}
                onClose={this.onCloseFilterRuleChooser}/>
              
              <div className={classes._leftToolbarButtons}>
                <IconButton
                  aria-label='More'
                  aria-haspopup='true'
                  onClick={this.onClickOpenDetailMenu}>
                    <MoreVertIcon aria-label='Mehr'/>
                </IconButton>
              </div>
              <Menu
                  id="detail-menu"
                  anchorEl={this.state.detailMenuAnchorEl}
                  open={this.state.detailMenuIsOpen}
                  onClose={this.onCloseDetailMenu}>
                {detailMenuItems}
              </Menu>

            </Toolbar>
          </AppBar>
          <main>
            <Grid container>
              
              <Grid item xs={12} className={classes._detailViewGridRow}>
              <Grid container>

              <Grid item xs={2} className={classes._detailViewGridRowIcon}>
                <AccessTimeIcon />
              </Grid>
              <Grid item xs={10} className={classes._detailViewGridRowContent}>
                <Typography>{formatDateTime(new Date(this.props.viewEvent.event._startsAtTimestamp))}</Typography>
              </Grid>

              </Grid>
              </Grid>

              <Grid item xs={12} className={classes._detailViewGridRow}>
              <Grid container>

              <Grid item xs={2} className={classes._detailViewGridRowIcon}>
                <LocationOnIcon />
              </Grid>
              <Grid item xs={10} className={classes._detailViewGridRowContent}>
                <PlaceLabel place={this.props.viewEvent.event.place}/>
              </Grid>

              </Grid>
              </Grid>

              <Grid item xs={12} className={classes._detailViewGridRow}>
              <Grid container>

              <Grid item xs={12} className={classes._detailViewContent}>
                <Typography align='justify'>{this.props.viewEvent.event.description}</Typography>
              </Grid>

              </Grid>
              </Grid>
            </Grid>
          </main>
        </React.Fragment>
    );
  };
};

export default withStyles(styles)(EventDetailView);
import * as React from 'react';

import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';

import EmailIcon from '@material-ui/icons/Email';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import DatePicker from 'material-ui-pickers/DatePicker'
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import DateFnsUtils from 'material-ui-pickers/utils/date-fns-utils';

import * as deLocale from 'date-fns/locale/de';

import { logError } from '../../eventer/common/common';
import { PositionContext } from '../../context';

import SearchLocationChooser from './search-location-chooser';

import { formatDate } from '../date-util';
import InfoPanel from './info-panel';
import { EventList } from './event-list';
import { EventController } from './event-controller';
import { WithStyles, Theme, createStyles, withStyles, Menu, MenuItem, ListItemIcon } from '@material-ui/core';
import { ViewEvent } from './view-types';
import { SearchLocation } from '../../common/search-locations';


interface EventViewProps extends WithStyles<typeof styles> {
  eventer: any;
  // TODO: type it!! (currently its an hybrid bridge)
  position: any;
  searchLocation: SearchLocation;
  searchRadius: number;
}

interface EventViewState {
  viewEvents: ViewEvent[];
  isLoading: boolean;
  selectedDate: Date;
  searchLocation: SearchLocation;
  searchRadius: number;
  filterEnabled: boolean;
  searchLocationChooserIsOpen: boolean;
  mainMenuIsOpen: boolean,
  mainMenuAnchorEl?: HTMLElement;
}

const styles = (theme: Theme) => createStyles({
  _appBar: {
  },
  _toolbar: {
    marginBottom: '4px',
    marginTop: '4px',
    minHeight: '48px', // otherwise there will be a top/bottom-"padding" due alignment
    paddingLeft: '12px',
    paddingRight: '0px',
  },
  _toolbarOpenButton: {
    marginRight: '4px',
    padding: '0px',
  },
  _toolbarDateLabel: {
    width: '50px',
  },
  _dialogTitle: {
    // TODO: how can we use the palette here (=primary.light)?
    backgroundColor: '#8eacbb',
    textAlign: 'center',
  },
  _leftToolbarButtons: {
    marginLeft: 'auto',
  },
});


// some needed custom declarations
// TODO: move to common types
declare const __appVersion: string;


class EventView extends React.Component<EventViewProps, EventViewState> {

  _bind() {
    this.needMoreEvents = this.needMoreEvents.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleSearchLocationChange = this.handleSearchLocationChange.bind(this);
    this.load = this.load.bind(this);
    this.onScroll = this.onScroll.bind(this);
    this.onUpdateEvents = this.onUpdateEvents.bind(this);
    this.renderFunc = this.renderFunc.bind(this);

    this.onClickOpenMainMenu = this.onClickOpenMainMenu.bind(this);
    this.onCloseMainMenu = this.onCloseMainMenu.bind(this);
    this.handleClickOpenSearchLocationChooser = this.handleClickOpenSearchLocationChooser.bind(this);
    this.handleCloseSearchLocationChooser = this.handleCloseSearchLocationChooser.bind(this);
    this.handleFilterEnabledChange = this.handleFilterEnabledChange.bind(this);
  }


  private eventController: EventController;


  constructor(props) {
    super(props);
    this._bind();

    const observer = {
      needMoreEvents: this.needMoreEvents,
      onUpdateEvents: this.onUpdateEvents,
    }

    this.eventController = new EventController(this.props.eventer, observer);
    this.state = {
      viewEvents: [],
      isLoading: true,
      selectedDate: new Date(),

      searchLocation: props.searchLocation,
      searchRadius: props.searchRadius,

      filterEnabled: this.eventController.isFilterEnabled(),

      searchLocationChooserIsOpen: false,
      mainMenuIsOpen: false,
      mainMenuAnchorEl: undefined,
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false);
    // TODO: initial syncFetchSize = 30 to speed up initial page load
    this.load(true);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let needsUpdate = false;

    if (prevState.selectedDate != this.state.selectedDate) {
      needsUpdate = true;
    }
    if (prevState.searchLocation != this.state.searchLocation) {
      needsUpdate = true;
    }

    // searchLocation is '__nearby' and coords are available
    if (this.state.searchLocation && this.state.searchLocation.id == '__nearby' &&
        (!prevProps.position || !prevProps.position.coords) && (this.props.position && this.props.position.coords)) {
      needsUpdate = true;
    }

    if (prevState.filterEnabled != this.state.filterEnabled) {
      if (this.eventController.setFilterEnabled(this.state.filterEnabled)) {
        needsUpdate = true;
      }
    }

    if (needsUpdate) {
      this.load(true);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll, false);
  }

  onScroll(e) {
    if (this.needMoreEvents()) {
      this.load(false);
    }
  }

  handleDateChange(date) {
    // TODO: check for simply scroll to event (may be this should be done on componentDidUpdate)
    this.setState((state, props) => {
      return {
        selectedDate: date,
        viewEvents: [],
      };
    });
  }

  needMoreEvents() {
    return (document.body.offsetHeight < window.innerHeight)
      || (document.body.offsetHeight - (window.innerHeight + window.scrollY) < 300);
  }

  async load(forceNew) {

    if (!forceNew && this.state.isLoading) {
      // already loading
      return;
    }

    this.setState((state, props) => {
      return { isLoading: true };
    });

    console.log('start loading next events...');

    if (forceNew) {

      // determine search geolocation
      let position;
      if (this.state.searchLocation.position) {

        // we use position from searchLocation
        position = this.state.searchLocation.position;

      } else if (this.props.position && this.props.position.coords) {

        // we use current position of user (= "__nearby" search)
        position = {
          lat: this.props.position.coords.lat,
          lon: this.props.position.coords.lon
        }
      } else {

        // No position availabe, may happen if geolocation of user has not been determined, yet.
        // We can break here, on getting current position load(true) will be triggered again.
        return null;
      }

      this.eventController.eventProvider.reset(position, this.state.searchRadius, this.state.selectedDate.getTime());
      this.setState((state, props) => {
        return { viewEvents: [] };
      });
    }

    try {
      await this.eventController.eventProvider.load();
    } catch (err) {
      logError(err, "event-view.js");
    }

    this.setState((state, props) => {
      return { isLoading: false };
    });
  }

  onUpdateEvents(allViewEventsById: Map<string, ViewEvent>, visibleViewEventsById: Map<string, ViewEvent>) {

      const _mergedEvents = Array.from(visibleViewEventsById.values()).sort((viewEvent1, viewEvent2) => {

        const e1 = viewEvent1.event;
        const e2 = viewEvent2.event;

        const diff = e1._startsAtTimestamp - e2._startsAtTimestamp;
        if (diff != 0) {
          return diff;
        }

        // same timestamp, sort by googlePlaceId
        if (e1.google_place_id < e2.google_place_id) {
          return -1;
        }
        if (e1.google_place_id > e2.google_place_id) {
          return 1;
        }

        // same ts and place, sort by event title
        if (e1.title < e2.title) {
          return -1;
        }
        if (e1.title > e2.title) {
          return 1;
        }

        // finally take eventId
        if (e1.id < e2.id) {
          return -1;
        }
        if (e1.id > e2.id) {
          return 1;
        }
        return 0;
      });

      this.setState((state, props) => {
        return { viewEvents: _mergedEvents };
      });

      // TODO: sometimes on setting less events (e.g. switch filter on) no "loadMore" will be triggered
  }


  renderFunc(date, invalidLabel) {
    return formatDate(date);
  }

  handleSearchLocationChange(searchLocation) {
    this.setState((state, props) => {
      return {
        searchLocation: searchLocation,
        searchLocationChooserIsOpen: false,
      };
    });
  }

  handleClickOpenSearchLocationChooser() {
    this.setState((state, props) => {
      return { searchLocationChooserIsOpen: true };
    });
  }

  handleCloseSearchLocationChooser() {
    this.setState((state, props) => {
      return { searchLocationChooserIsOpen: false };
    });
  }

  handleFilterEnabledChange(e) {
    const checked = e.target.checked;
    this.setState((state, props) => {
      return { filterEnabled: checked };
    });
  }

  onClickOpenMainMenu(e) {
    const anchorEl = e.currentTarget;
    this.setState((state, props) => {
      return {
        mainMenuIsOpen: true,
        mainMenuAnchorEl: anchorEl,
      };
    });
  }

  onCloseMainMenu() {
    this.setState((state, props) => {
      return { mainMenuIsOpen: false };
    });
  }


  render() {

    const { classes } = this.props;
    return (
      <React.Fragment>

          <AppBar
              position='sticky'
              color='secondary'
              className={classes._appBar}>
            <Toolbar className={classes._toolbar}>

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <IconButton className={classes._toolbarOpenButton}>
                  <KeyboardArrowDownIcon />
                </IconButton>
                <DatePicker

                    autoOk={true}
                    clearable={false}
                    disablePast={true}

                    // @ts-ignore
                    className={classes._toolbarDateLabel}

                    invalidDateMessage={null}
                    maxDateMessage={null}
                    minDateMessage={null}

                    leftArrowIcon={<KeyboardArrowLeftIcon/>}
                    rightArrowIcon={<KeyboardArrowRightIcon/>}
                    value={this.state.selectedDate}
                    onChange={this.handleDateChange}
                    cancelLabel="Abbrechen"
                    showTodayButton={true}
                    todayLabel="Heute"

                    fullWidth={false}

                    InputProps={{
                      disableUnderline: true,
                      fullWidth: false,
                    }}

                    labelFunc={this.renderFunc}>
                </DatePicker>
              </MuiPickersUtilsProvider>

              <IconButton
                  aria-labelledby="search-location-chooser-title"
                  onClick={this.handleClickOpenSearchLocationChooser}
                  className={classes._toolbarOpenButton}>
                <KeyboardArrowDownIcon />
              </IconButton>
              <Typography
                  variant='subheading'
                  onClick={this.handleClickOpenSearchLocationChooser}
                  noWrap={true}
                  >
                {this.state.searchLocation.name}
              </Typography>

              <SearchLocationChooser
                open={this.state.searchLocationChooserIsOpen}
                onClose={this.handleCloseSearchLocationChooser}
                onSearchLocationChanged={this.handleSearchLocationChange}/>

              <Switch
                checked={this.state.filterEnabled}
                onChange={this.handleFilterEnabledChange}
                value="filterOn"
                color='primary'/>
              <Typography
                  variant='subheading'
                  // TODO: trigger filter
                  //onClick={...}
                  >
                Filter
              </Typography>


              <div className={classes._leftToolbarButtons}>
                <IconButton
                  aria-label='More'
                  aria-haspopup='true'
                  onClick={this.onClickOpenMainMenu}>
                    <MoreVertIcon aria-label='More'/>
                </IconButton>
              </div>
              <Menu
                  id="main-menu"
                  anchorEl={this.state.mainMenuAnchorEl}
                  open={this.state.mainMenuIsOpen}
                  onClose={this.onCloseMainMenu}>
                <MenuItem key={'contact'}>
                  <ListItemIcon>
                    <EmailIcon />
                  </ListItemIcon>
                  <a href='mailto:hello@eventer.app' target="_top" style={{ textDecoration: 'none' }}>
                    <Typography>hallo@eventer.app</Typography>
                  </a>
                </MenuItem>
                <MenuItem key={'version'}>
                  <ListItemIcon>
                    <InfoIcon />
                  </ListItemIcon>
                  <Typography>Version: {__appVersion}</Typography>
                </MenuItem>
              </Menu>
            </Toolbar>

            <PositionContext.Consumer>{ pos => {

              let _isLoading = this.state.isLoading;

              // special case: waiting for geolocation
              // TODO: should be part of state handling!
              if (!_isLoading && this.state.searchLocation.id == '__nearby' && pos.enabled === undefined) {
                _isLoading = true;
              }

              return _isLoading ? (<LinearProgress color='primary'/>) : (null)
            }}
            </PositionContext.Consumer>
          </AppBar>

          <main>

            <PositionContext.Consumer>{ pos => {

              // check for some use case where
              if (this.state.searchLocation.id == '__nearby') {

                // '__nearby'

                // still waiting for geolocation info?
                if (pos.enabled === undefined) {
                  return (
                    <InfoPanel
                      mainText='eventer.app wartet auf Standortinformation' />
                  )
                }

                // geolocation blocked?
                if (pos.enabled === false) {
                  return (
                    <InfoPanel
                      mainText='Bitte erlaube eventer.app den Zugriff auf Deinen Standort, damit Veranstaltungen in Deiner Nähe anzeigt werden können oder wähle eine andere Region.' />
                  )
                }
              }

              // check: no events found
              if (!this.state.isLoading && this.state.viewEvents && this.state.viewEvents.length == 0) {

                if (navigator.onLine) {
                  return (
                    <InfoPanel
                      mainText='Es wurden leider keine Veranstaltungen gefunden, bitte wähle andere Suchkriterien.'
                      infoText='(eventer.app ist aktuell eine Beta-Version mit Veranstaltungen für die Region Mittelsachsen)' />
                  )
                } else {
                  return (
                    <InfoPanel
                      mainText='Bitte prüfe Deine Internetverbindung.'
                      infoText='Es konnten keine Veranstaltungen geladen werden.' />
                  )
                }
              }

              // all ok, we can show events
              return (<EventList viewEvents={this.state.viewEvents} eventController={this.eventController}/>);
            }}
            </PositionContext.Consumer>

          </main>

      </React.Fragment>
  )};
};

export default withStyles(styles)(EventView);

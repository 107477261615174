import '../../css/fonts/roboto.css';

import * as React from 'react';
import update from 'immutability-helper';

import CssBaseline from '@material-ui/core/CssBaseline';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';

import { PositionContext, PositionContextType } from '../context';

import eventerTheme from './theme';
import EventView from './events/event-view';
import { SearchLocation, getStoredSearchLocation } from '../common/search-locations';


interface EventerAppProps {
  eventer: any;
}

interface EventerAppState {
  position: PositionContextType;
  searchLocation: SearchLocation;
}

export class EventerApp extends React.Component<EventerAppProps, EventerAppState> {

  _bind() {
    this.onPositionChanged = this.onPositionChanged.bind(this);
  }

  constructor(props) {
    super(props);
    this._bind();

    this.state = {
      position: {},
      searchLocation: getStoredSearchLocation(),
    };

    if ("geolocation" in navigator) {
      // TODO: error callback?
      // TODO: unregister on endApplication (with watchId)
      const watchId = navigator["geolocation"].watchPosition(
        this.onPositionChanged,
        () => {
          console.log('geolocation disallowed :-(');
          this.setState(update(this.state, {
            position: { $set: { enabled: false }},
          }));
        }, {
          enableHighAccuracy: true,
          maximumAge: 20000 // at least 20 seconds old
        });
    } else {
      console.log("no geolocation ... :-/");
    }
  }

  onPositionChanged(position: GeolocationPosition) {
    console.log('===> position changed', position);
    const newPosition = {
      enabled: true,
      coords: {
        lat: position.coords.latitude,
        lon: position.coords.longitude,
      },
      timestamp: position.timestamp,
    };
    this.setState(update(this.state, {
      position: { $set: newPosition },
    }));
  }

  render() {
    return (
      <PositionContext.Provider value={ this.state.position }>
        <CssBaseline />
        <MuiThemeProvider theme={eventerTheme}>
          <EventView
            eventer={ this.props.eventer }
            position={ this.state.position }
            searchLocation={ this.state.searchLocation }
            searchRadius={ 20000 }/>
        </MuiThemeProvider>
      </PositionContext.Provider>)
  }
}

import { Event as DbEvent, Place as DbPlace } from "./eventer-db";

export class Place {
  readonly placeEntity: DbPlace;

  constructor(dbPlace: DbPlace) {
    this.placeEntity = dbPlace;
  }
}

export class Event {
  readonly eventEntity: DbEvent;
  id: string;
  title: string;
  description?: string;
  google_place_id: string;
  categories?: string[];

  _updatedAtTimestamp: number;
  _startsAtTimestamp: number;

  readonly place: Place;

  constructor(dbEvent: DbEvent, dbPlace: DbPlace) {
    this.eventEntity = dbEvent;
    this.place = new Place(dbPlace);

    this.id = this.eventEntity.id;
    this.title = this.eventEntity.title || ("event #" + dbEvent.id);
    this.description = this.eventEntity.description;
    this.google_place_id = this.eventEntity.google_place_id;
    this.categories = this.eventEntity.categories;
    this._updatedAtTimestamp = this.eventEntity._updatedAtTimestamp;
    this._startsAtTimestamp = this.eventEntity._startsAtTimestamp;
  }
}

import { Event } from "../../eventer/eventer-types";

export class ViewEvent {
  
  readonly event: Event;
  isFiltered: boolean;

  constructor(event: Event, isFiltered: boolean) {
    this.event = event;
    this.isFiltered = isFiltered;
  }
}
import * as React from 'react';

import EventListItem from './event-list-item';
import { ViewEvent } from './view-types';
import { EventController } from './event-controller';

interface EventListProps {
  viewEvents: ViewEvent[];
  eventController: EventController;
}

export class EventList extends React.Component<EventListProps, {}> {

  render() {
    return this.props.viewEvents.map(viewEvent => {
      return (<EventListItem key={viewEvent.event.id} viewEvent={viewEvent} eventController={this.props.eventController} />);
    });
  }
}
import * as React from 'react';

import { PropTypes, StyledComponentProps } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';

import { PositionContext } from '../../context';

import { DistanceLabel } from '../common/distance-label';
import { Place } from '../../eventer/eventer-types';


interface PlaceLabelProps extends StyledComponentProps {
  
  // delegated to Typography
  color?: PropTypes.Color | 'textSecondary' | 'error';
  
  place: Place;
}

const styles = {
  bullet: {
    display: 'inline-block',
    margin: '0 3px 0 4px',
    //transform: 'scale(0.8)',
  },
};

class PlaceLabel extends React.Component<PlaceLabelProps, {}> {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <PositionContext.Consumer>{ pos => (
        <Typography color={ this.props.color || 'default' }>
          { this.props.place.placeEntity.name }
          { pos.coords ? (
              <React.Fragment>
                <span className={this.props.classes ? this.props.classes.bullet : undefined}>•</span>
                <DistanceLabel
                  pos1={pos.coords}
                  pos2={{
                    lat: + this.props.place.placeEntity.location.lat,
                    lon: + this.props.place.placeEntity.location.lon,
                  }}/>
              </React.Fragment>) : (null)
          }
        </Typography>
        )}
      </PositionContext.Consumer>
    )
  }
}

export default withStyles(styles)(PlaceLabel);
import * as React from 'react';
import update from 'immutability-helper';

import { Theme, createStyles, withStyles, WithStyles, Dialog, List, ListItem, ListItemText, DialogTitle, DialogActions, Button, Checkbox } from '@material-ui/core';
import { EventController } from './event-controller';
import { ViewEvent } from './view-types';
import { RuleEntry } from './event-filter';


interface EventFilterRuleChooserProps extends WithStyles<typeof styles> {
  eventController: EventController;
  viewEvent: ViewEvent;
  open: boolean;
  onClose: () => void;
}

interface EventFilterRuleChooserState {
  ruleEntries: RuleEntry[]
}

const styles = (theme: Theme) => createStyles({
  _listItem: {
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  _dialogList: {
    padding: '0px',
  },
})


class EventFilterRuleChooser extends React.Component<EventFilterRuleChooserProps, EventFilterRuleChooserState> {

  public static defaultProps: Partial<EventFilterRuleChooserProps> = {
    open: true
  }

  state: EventFilterRuleChooserState = {
    ruleEntries: []
  }


  componentWillReceiveProps(nextProps: EventFilterRuleChooserProps) {
    // if props.open is updated to 'true' we have to (re-)initialize filter rules
    if (nextProps.open && !this.props.open) {
      this.setState(update(this.state, {
        ruleEntries: { $set: this.props.eventController.buildRuleEntries(this.props.viewEvent.event)},
      }));
    }
  }


  private onRuleEntryChange = (ruleEntry) => (e) => {
    ruleEntry.enabled = !ruleEntry.enabled;
    this.forceUpdate();
  }

  private onApplyClick = (e) => {
    this.state.ruleEntries.forEach(ruleEntry => {
      if (ruleEntry.enabled) {
        this.props.eventController.addEventFilterRule(ruleEntry.rule);
      } else {
        this.props.eventController.removeEventFilterRule(ruleEntry.rule);
      }
    });
    this.props.onClose();
  }

  
  render() {
    
    const { classes } = this.props;
    
    const listItems = this.state.ruleEntries.map(ruleEntry => {
      return (
        <ListItem key={ruleEntry.id} className={classes._listItem}>
          <Checkbox
            checked={ruleEntry.enabled}
            color="primary"
            onChange={this.onRuleEntryChange(ruleEntry)}
            value={ruleEntry.id}/>
          <ListItemText
            onClick={this.onRuleEntryChange(ruleEntry)}
            primary={ruleEntry.text}/>
        </ListItem>
      )
    })

    return (
      <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          maxWidth={'md'}>
        <DialogTitle id="rule-chooser-title" color="primary">Was soll gefiltert werden?</DialogTitle>
        <List className={classes._dialogList}>{listItems}</List>
        <DialogActions>
          <Button onClick={this.onApplyClick} color="primary" autoFocus>
            Übernehmen
          </Button>
        </DialogActions>
      </Dialog>)
  }
}

export default withStyles(styles)(EventFilterRuleChooser);
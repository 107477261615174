import { EventProvider, LoadObserver } from "./event-provider";
import { EventFilterRule } from "./event-filter";
import { Event } from "../../eventer/eventer-types";

export class EventController {

  readonly eventProvider: EventProvider;


  constructor(eventer: any, loadObserver: LoadObserver) {

    this.eventProvider = new EventProvider(eventer, loadObserver);

    this.loadFilter();
    this.loadFilterEnabled();
  }


  /* rule entries */

  buildRuleEntries(event: Event) {
    return this.eventProvider.buildRuleEntries(event);
  }

  addEventFilterRule(rule: EventFilterRule): void {
    console.log('adding filter rule: ', rule);

    if (this.eventProvider.addEventFilterRule(rule)) {
      this.persistFilter();
    }
  }
  
  removeEventFilterRule(rule: EventFilterRule): void {
    console.log('removing filter rule: ', rule);

    if (this.eventProvider.removeEventFilterRule(rule)) {
      this.persistFilter();
    }
  }

  loadFilter(): void {
    
    let filterData = undefined;

    const filterDataJson = localStorage.getItem('__eventer.filter.default');
    if (filterDataJson) {
      try {
        filterData = JSON.parse(filterDataJson);
      } catch (err) {
        console.log(`error on parsing filter data`, err);
      }
    }

    this.eventProvider.initializeFilter(filterData);
  }

  private persistFilter(): void {
    const filterData = JSON.stringify(this.eventProvider.buildFilterData());
    console.log(`filter data: ${filterData}`);
    localStorage.setItem('__eventer.filter.default', filterData);
  }


  /* filterEnabled flag */

  private loadFilterEnabled() {
    const filterEnabled = localStorage.getItem('__eventer.filterEnabled') == 'true';
    this.eventProvider.setFilterEnabled(filterEnabled);
  }

  isFilterEnabled(): boolean {
    return this.eventProvider.filterEnabled;
  }

  setFilterEnabled(enabled: boolean): boolean {
    if (this.eventProvider.setFilterEnabled(enabled) == false) {
      return false;
    }
    localStorage.setItem('__eventer.filterEnabled', '' + enabled);
    return true;
  }
}